// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-blog-sizing-chart-readability-improved-js": () => import("./../../../src/pages/blog/sizing-chart-readability-improved.js" /* webpackChunkName: "component---src-pages-blog-sizing-chart-readability-improved-js" */),
  "component---src-pages-blog-user-voice-sessions-js": () => import("./../../../src/pages/blog/user-voice-sessions.js" /* webpackChunkName: "component---src-pages-blog-user-voice-sessions-js" */),
  "component---src-pages-case-study-maisonkitsune-js": () => import("./../../../src/pages/case-study-maisonkitsune.js" /* webpackChunkName: "component---src-pages-case-study-maisonkitsune-js" */),
  "component---src-pages-case-study-pyrenex-js": () => import("./../../../src/pages/case-study-pyrenex.js" /* webpackChunkName: "component---src-pages-case-study-pyrenex-js" */),
  "component---src-pages-case-study-shilton-js": () => import("./../../../src/pages/case-study-shilton.js" /* webpackChunkName: "component---src-pages-case-study-shilton-js" */),
  "component---src-pages-case-study-volcom-js": () => import("./../../../src/pages/case-study-volcom.js" /* webpackChunkName: "component---src-pages-case-study-volcom-js" */),
  "component---src-pages-clients-js": () => import("./../../../src/pages/clients.js" /* webpackChunkName: "component---src-pages-clients-js" */),
  "component---src-pages-cookie-policy-js": () => import("./../../../src/pages/cookie-policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-cross-sell-js": () => import("./../../../src/pages/cross-sell.js" /* webpackChunkName: "component---src-pages-cross-sell-js" */),
  "component---src-pages-dashboard-js": () => import("./../../../src/pages/dashboard.js" /* webpackChunkName: "component---src-pages-dashboard-js" */),
  "component---src-pages-demo-js": () => import("./../../../src/pages/demo.js" /* webpackChunkName: "component---src-pages-demo-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-integration-js": () => import("./../../../src/pages/integration.js" /* webpackChunkName: "component---src-pages-integration-js" */),
  "component---src-pages-plugin-js": () => import("./../../../src/pages/plugin.js" /* webpackChunkName: "component---src-pages-plugin-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-retail-js": () => import("./../../../src/pages/retail.js" /* webpackChunkName: "component---src-pages-retail-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-templates-blogpost-js": () => import("./../../../src/templates/blogpost.js" /* webpackChunkName: "component---src-templates-blogpost-js" */)
}

